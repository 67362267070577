import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { commRequest } from '../apis'

const initialState = {
  content: {},
  loading: 'idle',
  error: ''
}

const getPriceMappingFromList = (list) => {
  const newPriceMapping = {}
  list.forEach(item => {
    newPriceMapping[item.stockId] = item.price ? item.price.price : 0
  })
  return newPriceMapping
}

export const fetchPriceMapping = createAsyncThunk('priceMapping/fetchPriceMapping', async (stockList, thunkAPI) => {
  const stockIdList = stockList.map(stockItem => stockItem.stockId)
  const stockIdsString = stockIdList.join(',')
  const { data } = await commRequest.get('', {
    headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
    params: {
      stockIds: stockIdsString,
      limit: 0,
    },
  })
  return getPriceMappingFromList(data.list)
})

export const priceMappingSlice = createSlice({
  name: 'priceMapping',
  initialState,
  reducers: {
    updatePriceMapping: (state, action) => {
      return { ...state, content: { ...state.content, ...action.payload } }
    },
    replacePriceMapping: (state, action) => {
      return { ...state, content: { ...action.payload } }
    },
  },
  extraReducers: {
    [fetchPriceMapping.fulfilled]: (state, action) => {
      state.content = action.payload
      state.loading = 'idle'
    },
    [fetchPriceMapping.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [fetchPriceMapping.rejected]: (state, action) => {
      state.loading = 'idle'
      state.error = '價格載入失敗'
    },
    'signout': (state, action) => {
      return initialState
    },
    'changeSalePoint': (state, action) => {
      return initialState
    },
  },
})

export const { updatePriceMapping, replacePriceMapping } = priceMappingSlice.actions

export const selectPriceMapping = state => state.priceMapping.content

export default priceMappingSlice.reducer