import { createSlice } from '@reduxjs/toolkit'

export const currentTempOrderIdSlice = createSlice({
  name: 'currentTempOrderId',
  initialState: '',
  reducers: {
    replaceCurrentTempOrderId: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    'checkout': (state, action) => {
      return ''
    },
    'signout': (state, action) => {
      return ''
    },
    'changeSalePoint': (state, action) => {
      return ''
    },
  },
})

export const currentExternalOrderIdSlice = createSlice({
  name: 'currentExternalOrderId',
  initialState: '',
  reducers: {
    replaceCurrentExternalOrderId: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    'checkout': (state, action) => {
      return ''
    },
    'signout': (state, action) => {
      return ''
    },
    'changeSalePoint': (state, action) => {
      return ''
    },
  },
})

export const currentSalesmanIdSlice = createSlice({
  name: 'currentSalesmanId',
  initialState: '',
  reducers: {
    replaceCurrentSalesmanId: (state, action) => {
      return action.payload
    }
  },
  extraReducers: {
    // 'checkout': (state, action) => {
    //   return ''
    // },
    'signout': (state, action) => {
      return ''
    },
  },
})

export const currentCashRegisterIdSlice = createSlice({
  name: 'currentCashRegisterId',
  initialState: '',
  reducers: {
    replaceCurrentCashRegisterId: (state, action) => {
      return action.payload
    }
  },
  extraReducers: {
    'signout': (state, action) => {
      return ''
    },
  },
})


export const { replaceCurrentSalesmanId } = currentSalesmanIdSlice.actions
export const { replaceCurrentTempOrderId } = currentTempOrderIdSlice.actions
export const { replaceCurrentExternalOrderId } = currentExternalOrderIdSlice.actions
export const { replaceCurrentCashRegisterId } = currentCashRegisterIdSlice.actions

export const selectCurrentTempOrderId = state => state.currentTempOrderId
export const selectCurrentExternalOrderId = state => state.currentExternalOrderId
export const selectCurrentSalesmanId = state => state.currentSalesmanId
export const selectCurrentCashRegisterId = state => state.currentCashRegisterId

export const currentTempOrderIdReducer = currentTempOrderIdSlice.reducer
export const currentExternalOrderIdReducer = currentExternalOrderIdSlice.reducer
export const currentSalesmanIdReducer = currentSalesmanIdSlice.reducer
export const currentCashRegisterIdReducer = currentCashRegisterIdSlice.reducer