import { createSlice } from '@reduxjs/toolkit'
import { SETTLE_HOUR } from '../constants'

const initialState = {}

export const visitsSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    replaceVisits: (state, action) => {
      return action.payload
    },
    updateVisits: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: {
    'signout': (state, action) => {
      return {}
    },
    'changeSalePoint': (state, action) => {
      return {}
    },
  },
})

export const { replaceVisits, updateVisits } = visitsSlice.actions

// 查看LocalStorage是否有儲存客次及客次紀錄日期，並依此改變Redux狀態
export const initialVisits = id => (dispatch, getState) => {
  const { userId, salePointId } = getState().user
  const currentSalePointId = id || salePointId
  const newVisitsDate = Math.ceil(
    (new Date().getTime() - (SETTLE_HOUR - 8) * 3600000) / 86400000
  )

  const localVisits = JSON.parse(
    localStorage.getItem(`visits/${userId}/${currentSalePointId}`)
  )

  if (!localVisits || localVisits.visitsDate !== newVisitsDate) {
    localStorage.setItem(`visits/${userId}/${salePointId}`, JSON.stringify({ visitsNum: 0, visitsDate: newVisitsDate }))
    dispatch(replaceVisits({ visitsNum: 0, visitsDate: newVisitsDate }))
  }
  else {
    localStorage.setItem(`visits/${userId}/${salePointId}`, JSON.stringify({ visitsNum: localVisits.visitsNum, visitsDate: localVisits.visitsDate }))
    dispatch(replaceVisits({ visitsNum: localVisits.visitsNum, visitsDate: localVisits.visitsDate }))
  }
}

// 檢查是否超過使用者設定的跨日時間，超過則重置
export const renewVisits = () => (dispatch, getState) => {
  const { userId, salePointId } = getState().user
  const newVisitsDate = Math.ceil(
    (new Date().getTime() - (SETTLE_HOUR - 8) * 3600000) / 86400000
  )

  const { visitsDate: currentVisitsDate } = getState().visits

  if (newVisitsDate !== currentVisitsDate) {
    localStorage.setItem(`visits/${userId}/${salePointId}`, JSON.stringify({ visitsNum: 0, visitsDate: newVisitsDate }))
    dispatch(replaceVisits({ visitsNum: 0, visitsDate: newVisitsDate }))
  }
}

// 增加客次，若超過使用者設定的跨日時間則重置
export const increaseVisits = () => (dispatch, getState) => {
  const { userId, salePointId } = getState().user
  const newVisitsDate = Math.ceil(
    (new Date().getTime() - (SETTLE_HOUR - 8) * 3600000) / 86400000
  )

  const { visitsNum: currentVisitsNum, visitsDate: currentVisitsDate } = getState().visits

  if (newVisitsDate !== currentVisitsDate) {
    localStorage.setItem(`visits/${userId}/${salePointId}`, JSON.stringify({ visitsNum: 0, visitsDate: newVisitsDate }))
    dispatch(replaceVisits({ visitsNum: 0, visitsDate: newVisitsDate }))
  }
  else {
    localStorage.setItem(`visits/${userId}/${salePointId}`, JSON.stringify({ visitsNum: currentVisitsNum + 1, visitsDate: currentVisitsDate }))
    dispatch(updateVisits({ visitsNum: currentVisitsNum + 1 }))
  }
}

export const selectVisits = state => state.visits

export default visitsSlice.reducer