import { createSlice } from '@reduxjs/toolkit'

const initialState = []

// Slice Part
export const posStripsSlice = createSlice({
  name: 'posStrips',
  initialState,
  reducers: {
    replacePosStrips: (state, action) => {
      return action.payload
    },
    clearPosStrips: (state, action) => {
      return []
    },
    addPosStrip: (state, action) => {
      return [...state, action.payload]
    },
    addPosStrips: (state, action) => {
      return [...state, ...action.payload]
    },
    deletePosStrip: (state, action) => {
      return state.filter(el => el.id !== action.payload)
    },
  },
  extraReducers: {
    'checkout': (state, action) => {
      return []
    },
    'signout': (state, action) => {
      return []
    },
    'changeSalePoint': (state, action) => {
      return []
    },
  },
})

export const { replacePosStrips, clearPosStrips, addPosStrip, addPosStrips, deletePosStrip } = posStripsSlice.actions

export const selectPosStripsSlice = state => state.posStripsSlice

export default posStripsSlice.reducer