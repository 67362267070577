import React, { useState, useMemo } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useMediaQuery, CssBaseline } from '@mui/material'
import { teal } from '@mui/material/colors'
import ColorModeContext from '../contexts/ColorModeContext'

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      main: '#f2af44',
      ...(mode === 'dark' && {
        main: teal[300],
      }),
    },
    secondary: {
      main: '#3e414f',
      matte: '#505877',
    },
    background: {
      default: '#ffffff',
      light: '#f5f5f5',
      paper: '#fafafa',
      darkPaper: '#efefef',
    },
    active: {
      main: '#d5e7ee',
      light: '#ddf2f9',
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 13,
  },
})

const withTheme = ChildComponent => {
  const ComposedComponent = props => {
    const [mode, setMode] = useState(
      useMediaQuery('(prefers-color-scheme: dark)')
        ? 'dark'
        : 'light'
    )

    const colorMode = useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
        }
      }), []
    )

    const theme = useMemo(
      () => (
        createTheme(getDesignTokens(mode))
      ), [mode]
    )

    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ChildComponent {...props} />
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }

  return ComposedComponent
}

export default withTheme