import { createSlice } from '@reduxjs/toolkit'
import { basicRequest, userRequest, companyRequest, salePointRequest } from '../apis'
import { replaceUser } from './userSlice'
import { replaceCurrentSalesmanId, replaceCurrentCashRegisterId } from './currentIdsSlice'
// import { updateCompanyInfo } from './companyInfoSlice'

const initialState = {
  authenticated: '',
  level: '',
  errorMessage: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authUser: (state, action) => {
      return { ...state, authenticated: action.payload }
    },
    authError: (state, action) => {
      return { ...state, errorMessage: action.payload }
    },
  },
  extraReducers: {
    'signout': (state, action) => {
      return initialState
    },
  }
})

// 登入 action
export const signin = (formProps) => async dispatch => {
  try {
    const encodedContent = window.btoa(formProps.userName + ':' + formProps.password)
    const { data: TokenData } = await userRequest.post('/login', null, {
      headers: {
        'Authorization': 'Basic ' + encodedContent,
        'x-cust': formProps.xCust,
      }
    })
    dispatch(authUser(TokenData.token))
    localStorage.setItem('token', TokenData.token)

    const { data: userData } = await userRequest.get('/me', {
      headers: { 'Authorization': 'Bearer ' + TokenData.token },
    })
    const { data: companyData } = await companyRequest.get('', {
      headers: { 'Authorization': 'Bearer ' + TokenData.token },
    })
    const { data: salePointsData } = await userRequest.get(`/${userData.userId}/salePoints`, {
      headers: { 'Authorization': 'Bearer ' + TokenData.token },
    })
    const { data: { logo: logoData } } = await basicRequest.get('/data/image', {
      headers: { 'Authorization': 'Bearer ' + TokenData.token },
    })

    const newUserInfo = { ...userData, company: companyData, salePoints: salePointsData, logo: logoData }

    const localRegisterId = localStorage.getItem(`cashRegisterId/${userData.userId}/${userData.salePointId}`)
    let cashRegisterId = ''
    if (!localRegisterId) {
      cashRegisterId = await getCashRegisterId(userData, TokenData.token)
      localStorage.setItem(`cashRegisterId/${userData.userId}/${userData.salePointId}`, cashRegisterId)
    }

    dispatch(replaceUser(newUserInfo))
    dispatch(replaceCurrentCashRegisterId(localRegisterId || cashRegisterId))
    dispatch(replaceCurrentSalesmanId(newUserInfo.userId))
    localStorage.setItem('userInfo', JSON.stringify(newUserInfo))

    return true
  } catch (e) {
    dispatch(authError(`驗證失敗: ${e.response?.data?.error || e.message}`))
    return false
  }
}

// 登出 action
export const signout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')

  return { type: 'signout' }
}


// Helper functions
const getCashRegisterId = async (userData, token) => {
  // const localCashRegisterId = userData.userId && userData.salePointId && localStorage.getItem(`cashRegisterId/${userData.userId}/${userData.salePointId}`)
  // const localCashRegisterId = JSON.parse(localStorage.getItem('userInfo'))?.cashRegisterId
  // console.log(localCashRegisterId)

  // if (localCashRegisterId) return localCashRegisterId

  if (userData.salePointId) {
    const { data: localCashRegisters } = await salePointRequest.get(`/${userData.salePointId}/cash-register`, {
      headers: { 'Authorization': 'Bearer ' + token },
    })
    return localCashRegisters[0].id
  } else {
    throw new Error('Default salePointId is not implemented')
  }
}

export const { authUser, authError } = authSlice.actions

export const selectAuthUser = state => state.auth.authenticated
export const selectAuthError = state => state.auth.errorMessage

export default authSlice.reducer