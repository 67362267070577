import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { memberRequest } from '../apis'

const initialState = []

export const fetchMembers = createAsyncThunk('members/fetchMembers', async (params, thunkAPI) => {
  const { data } = await memberRequest.get('', {
    headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
    params: {
      sort: 'create_time desc',
      ...params
    },
  })
  return data.list
})

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    replaceComms: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    [fetchMembers.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchMembers.rejected]: (state, action) => {
      return []
    },
    'signout': (state, action) => {
      return []
    },
    'changeSalePoint': (state, action) => {
      return []
    },
  },
})

export const { replaceComms } = membersSlice.actions

export const selectMembers = state => state.members

export default membersSlice.reducer