import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { commClassRequest } from '../apis'

export const fetchCommClasses = createAsyncThunk('commClasses/fetchCommClasses', async (params, thunkAPI) => {
  try {
    const { data } = await commClassRequest.get('', {
      headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
      params: {
        limit: 'zero',
        ...params,
      },
    })
    return data.list
  } catch (error) {
    return []
  }
})

export const commClassesSlice = createSlice({
  name: 'commClasses',
  initialState: [],
  reducers: {
    createCommClass: (state, action) => {
      return [...state, action.payload]
    },
    editCommClass: (state, action) => {
      return state.map(el => el.ID === action.payload.ID ? action.payload : el)
    },
    deleteCommClass: (state, action) => {
      return state.filter(commClass => commClass.ID !== action.payload)
    },
  },
  extraReducers: {
    [fetchCommClasses.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchCommClasses.rejected]: (state, action) => {
      return []
    },
    'signout': (state, action) => {
      return []
    },
  },
})

export const { createCommClass, editCommClass, deleteCommClass } = commClassesSlice.actions

export const selectCommClasses = state => state.commClasses

export default commClassesSlice.reducer