import { configureStore } from '@reduxjs/toolkit'
import priceMappingReducer from './slices/priceMappingSlice'
import paymentMethodsReducer from './slices/paymentMethodsSlice'
import authReducer from './slices/authSlice'
import userReducer from './slices/userSlice'
import commsReducer from './slices/commsSlice'
import commClassesReducer from './slices/commClassesSlice'
import posStripsReducer from './slices/posStripsSlice'
import posStripsSumReducer from './slices/posStripsSumSlice'
import currentMemberReducer from './slices/currentMemberSlice'
import membersReducer from './slices/membersSlice'
import invoiceInfoReducer from './slices/invoiceInfoSlice'
import receivedAmountsReducer from './slices/receivedAmountsSlice'
import creditCardResponseSlice from './slices/creditCardResponseSlice'
import {
  externalOrdersReducer,
  activeExternalOrderReducer,
  selectedExternalOrdersReducer,
} from './slices/externalOrdersSlice'
import salesReducer from './slices/salesSlice'
import salePaymentsReducer from './slices/salePaymentsSlice'
import tempOrdersReducer from './slices/tempOrdersSlice'
import paletteReducer from './slices/paletteSlice'
import companyInfoReducer from './slices/companyInfoSlice'
import posLayoutsReducer from './slices/posLayoutsSlice'
import posLayoutDraftsReducer from './slices/posLayoutDraftsSlice'
import visitsReducer from './slices/visitsSlice'
import orderModeReducer from './slices/orderModeSlice'
import {
  currentSalesmanIdReducer,
  currentTempOrderIdReducer,
  currentExternalOrderIdReducer,
  currentCashRegisterIdReducer,
} from './slices/currentIdsSlice'

const localUserInfo = JSON.parse(localStorage.getItem('userInfo'))
const localRegisterId = (localUserInfo && localUserInfo.userId && localUserInfo.salePointId && localStorage.getItem(`cashRegisterId/${localUserInfo.userId}/${localUserInfo.salePointId}`)) || ''
const localOrderMode = (localUserInfo && localUserInfo.userId && localUserInfo.salePointId && localStorage.getItem(`orderMode/${localUserInfo.userId}/${localUserInfo.salePointId}`)) || ''

export const store = configureStore({
  reducer: {
    priceMapping: priceMappingReducer,
    paymentMethods: paymentMethodsReducer,

    auth: authReducer,
    user: userReducer,
    comms: commsReducer,
    commClasses: commClassesReducer,
    posStrips: posStripsReducer,
    posStripsSum: posStripsSumReducer,
    receivedAmounts: receivedAmountsReducer,
    members: membersReducer,
    invoiceInfo: invoiceInfoReducer,
    creditCardResponse: creditCardResponseSlice,

    tempOrders: tempOrdersReducer,
    externalOrders: externalOrdersReducer,
    sales: salesReducer,
    salePayments: salePaymentsReducer,

    currentMember: currentMemberReducer,
    currentSalesmanId: currentSalesmanIdReducer,
    currentTempOrderId: currentTempOrderIdReducer,
    currentExternalOrderId: currentExternalOrderIdReducer,
    currentCashRegisterId: currentCashRegisterIdReducer,
    selectedExternalOrders: selectedExternalOrdersReducer,
    activeExternalOrder: activeExternalOrderReducer,

    posLayouts: posLayoutsReducer,
    posLayoutDrafts: posLayoutDraftsReducer,
    palette: paletteReducer,
    visits: visitsReducer,
    orderMode: orderModeReducer,
    companyInfo: companyInfoReducer,
  },
  preloadedState:
  {
    auth: { authenticated: localStorage.getItem('token') },
    user: localUserInfo || {},
    currentSalesmanId: localUserInfo?.userId || '',
    currentCashRegisterId: localRegisterId,
    orderMode: localOrderMode,
    // companyInfo: { cashRegisterId: localCompanyInfo },
  },
})