import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  taxIDNumber: '',
  carrierNumber: '',
}

const invoiceInfoSlice = createSlice({
  name: 'invoiceInfo',
  initialState,
  reducers: {
    updateInvoiceInfo: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
});

export const {
  updateInvoiceInfo,
} = invoiceInfoSlice.actions

export const selectInvoiceInfo = state => state.invoiceInfo

export default invoiceInfoSlice.reducer