import { createSlice } from '@reduxjs/toolkit'
import { increaseVisits } from './visitsSlice'
import { salesRequest } from '../apis'
import { deleteTempOrderWithLocal } from './tempOrdersSlice'
import { MySwal, fireDialogToast } from '../helpers/sweetAlert'

const initialState = {
  quantity: 0,
  total: 0,
  // 各原價總和
  primitiveTotal: 0,
}

const mapStripsData = strips => {
  return strips.map((item, index) => (
    {
      itemNo: index + 1,
      stockId: item.stockId,
      quantity: item.quantity,
      price: item.price,
      salePrice: item.salePrice,
      subTotal: Number.isNaN(Number(item.total)) ? item.price * item.quantity : Number(item.total),
      discountRate: (item.price === 0)
        ? 100
        : Number(((item.total * 100) / item.originalTotal).toFixed()),
      ...(item.tempSalesDetailsId && { tempSalesDetailsId: item.tempSalesDetailsId }),
      ...(item.customerOrdersDetailsId && { customerOrdersDetailsId: item.customerOrdersDetailsId }),
      ...(item.packageId && { packageId: item.packageId }),
      ...(item.parentStockId && { parentStockId: item.parentStockId }),
    }
  ))
}

const convertReceivedAmounts = (receivedAmounts, giveChargeAmount, creditCardInfo) => {
  // 如果有找零金額，用已收的現金來扣除
  const newReceivedAmounts = giveChargeAmount
    ? { ...receivedAmounts, 1: (receivedAmounts[1] - giveChargeAmount) }
    : receivedAmounts

  const paymentIds = Object.keys(newReceivedAmounts)
  return paymentIds.map(paymentId => ({
    salePointPaymentId: paymentId,
    value: newReceivedAmounts[paymentId],
    ...((paymentId === '2' && creditCardInfo?.success && creditCardInfo?.response) && {
      creditCard: {
        transType: creditCardInfo.response.transType,
        hostId: creditCardInfo.response.hostId,
        invoiceNo: creditCardInfo.response.receiptNo,
        cardNo: creditCardInfo.response.cardNo,
        transDate: creditCardInfo.response.transDate,
        transTime: creditCardInfo.response.transTime,
        approvalCode: creditCardInfo.response.approvalNo,
      }
    }),
  }))
}


export const posStripsSumSlice = createSlice({
  name: 'posStripsSum',
  initialState,
  reducers: {
    replacePosStripsSum: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    'checkout': (state, action) => {
      return initialState
    },
    'signout': (state, action) => {
      return initialState
    },
    'changeSalePoint': (state, action) => {
      return initialState
    },
  },
})

export const checkout = (params) => async (dispatch, getState) => {
  try {
    const { auth: { authenticated }, user, posStrips, posStripsSum, receivedAmounts, currentSalesmanId, currentTempOrderId, currentExternalOrderId, currentCashRegisterId, currentMember, creditCardResponse, orderMode } = getState()
    const sandbox = window.configBridge && window.configBridge.getConfig().sandbox

    const data = {
      pos: {
        salePointId: user.salePointId,
        cashRegisterId: currentCashRegisterId,
        locCode: user.salePoint.locationCode,
        ...(sandbox && { sandbox: sandbox }),
      },
      sales: {
        userId: user.userId,
        member: currentMember.id,
        total: posStripsSum.primitiveTotal,
        actualTotal: posStripsSum.total,
        quantity: posStripsSum.quantity,
        discountRate: (Math.round(posStripsSum.primitiveTotal) === 0 || Math.round(posStripsSum.total) === 0)
          ? 100
          : Number(((posStripsSum.total * 100) / posStripsSum.primitiveTotal).toFixed()),
        discountTotal: Number((posStripsSum.primitiveTotal - posStripsSum.total).toFixed()),
        ...(currentSalesmanId && { salesmanCode: currentSalesmanId }),
        ...(currentExternalOrderId && {
          [orderMode === 'normal' ? 'customerOrdersIds' : 'tempSalesIds']: currentExternalOrderId
        }),
        ...(orderMode === 'normal' && { isOrder: true }),
        ...(params.taxId && { uniformNumber: params.taxId }),
        ...(params.invoiceCarrier && { carrierNum: params.invoiceCarrier }),
        ...(params.notes && { notes: params.notes }),
      },
      salesDetails: mapStripsData(posStrips),
      salesPayments: Math.round(posStripsSum.total) === 0
        ? [{
          salePointPaymentId: '1',
          value: 0,
        }]
        : convertReceivedAmounts(
          receivedAmounts, params.giveChargeAmount, creditCardResponse
        ),
    }

    const headers = { 'Authorization': 'Bearer ' + authenticated }

    const { data: { salesId } } = await salesRequest.post('', data, { headers })
    const { data: salesInfo } = await salesRequest.get(`/${salesId}`, { headers })

    if (currentTempOrderId) {
      dispatch(deleteTempOrderWithLocal(currentTempOrderId))
    }

    dispatch({ type: 'checkout' })
    dispatch(increaseVisits())

    MySwal.fire({
      icon: 'success',
      title: '完成結帳',
      width: '20rem',
      timer: 1500,
      showConfirmButton: false,
    })

    return salesInfo
  } catch (error) {
    fireDialogToast(
      error.response?.data?.error
        ? `Status Code ${error.response.status}: ${error.response.data.error}`
        : error,
      'error'
    )
    return false
  }
}

export const { replacePosStripsSum } = posStripsSumSlice.actions

export const selectPosStripsSumSlice = state => state.posStripsSumSlice

export default posStripsSumSlice.reducer