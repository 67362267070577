import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  companyId: '',
  companyName: '',
  // cashRegisterId: '774a3e8f-b060-11ea-9e33-42010a8c0fd4',
  cashRegisterId: '',
}

export const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState,
  reducers: {
    updateCompanyInfo: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
  extraReducers: {
    'signout': (state, action) => {
      return initialState
    },
  },
})

export const { updateCompanyInfo } = companyInfoSlice.actions

export const selectCompanyInfo = state => state.companyInfo

export default companyInfoSlice.reducer