import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { salePointRequest } from '../apis'
// import { omit } from 'lodash-es'

const initialState = {
  loading: 'initial',
  content: {},
}

export const fetchPaymentMethods = createAsyncThunk('paymentMethods/fetchPaymentMethods', async (params, thunkAPI) => {
  const { data } = await salePointRequest.get('/payment', {
    headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
    params: { isPrint: '1', sort: 'sale_point_payments_type_id asc', },
  })
  return data
})

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    replacePaymentMethods: (state, action) => {
      state.content = action.payload
    },
  },
  extraReducers: {
    [fetchPaymentMethods.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [fetchPaymentMethods.fulfilled]: (state, action) => {
      const typeObj = {}

      action.payload.forEach(payment => {
        if (typeObj[payment.id]) return
        typeObj[payment.id] = payment
      })

      // 以 salePointPaymentsTypeId 分類的方法如下
      // action.payload.forEach(payment => {
      //   if (typeObj[payment.salePointPaymentsTypeId]) {
      //     typeObj[payment.salePointPaymentsTypeId].branches.push(
      //       omit(payment, 'salePointPaymentType')
      //     )
      //   } else {
      //     typeObj[payment.salePointPaymentsTypeId] = {
      //       ...payment.salePointPaymentType,
      //       branches: [omit(payment, 'salePointPaymentType')],
      //     }
      //   }
      // })
      state.loading = 'idle'
      state.content = typeObj
    },
    [fetchPaymentMethods.rejected]: (state, action) => {
      state.loading = 'pending'
    },
    // 'signout': (state, action) => {

    // },
    // 'changeSalePoint': (state, action) => {

    // },
  },
})

export const {
  replacePaymentMethods,
} = paymentMethodsSlice.actions

export const selectPaymentMethods = state => state.paymentMethods.content

export default paymentMethodsSlice.reducer