import { createSlice } from '@reduxjs/toolkit'
import { fetchPosLayouts } from './posLayoutsSlice'

const initialState = {}

export const changeSalePoint = id => async (dispatch, getState) => {
  const salePoints = getState().user.salePoints
  const selectedSalePoint = salePoints.find(el => el.id === id)
  dispatch({
    type: 'changeSalePoint',
    payload: { id, selectedSalePoint }
  })
  dispatch(fetchPosLayouts(id))
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    replaceUser: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    'initializeUser': (state, action) => {
      return action.payload
    },
    'signout': (state, action) => {
      return {}
    },
    'changeSalePoint': (state, action) => {
      const newUserInfo = {
        ...state,
        salePointId: action.payload.id,
        salePoint: action.payload.selectedSalePoint,
      }
      localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
      return newUserInfo
    },
  },
})

export const { replaceUser } = userSlice.actions

export const selectUser = state => state.user

export default userSlice.reducer