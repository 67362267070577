import { v4 as uuidv4 } from 'uuid'

// 以 configBridge 判斷是否在 Electron 中
export const isElectron = Boolean(window.configBridge)

// POS首頁觸控鍵盤一開始是輸入 數字(quantityMode) 或 折扣(discountMode) 等
export const INITIAL_KEYBOARD_MODE = 'quantityMode'

// 折扣是 定價乘以數量後減去折扣數(multi) 
// 或 定價減去折扣數後再乘以數量(single)
export const MULTIPLE_MODE = 'multi'

// 訂單已付金額中文對照
export const RECEIVED_METHOD_MAPPING = {
  cash: '現金',
  credit: '信用卡',
  remittance: '匯款',
  campaign: '活動優惠',
  discount: '整筆折扣',
  point: '點數',
  stored: '儲值金',
}

// 客次於當日幾點清零(0到24的數字 不包含24本身)
export const SETTLE_HOUR = 17

// 觸控表格相關初始化資料
export const initialColor = '#fcfcfc'




export const initialGrid = [
  { backgroundColor: initialColor, stockSeq: 0, },
  { backgroundColor: initialColor, stockSeq: 1, },
  { backgroundColor: initialColor, stockSeq: 2, },
  { backgroundColor: initialColor, stockSeq: 3, },
  { backgroundColor: initialColor, stockSeq: 4, },
  { backgroundColor: initialColor, stockSeq: 5, },
  { backgroundColor: initialColor, stockSeq: 6, },
  { backgroundColor: initialColor, stockSeq: 7, },
  { backgroundColor: initialColor, stockSeq: 8, },
  { backgroundColor: initialColor, stockSeq: 9, },
  { backgroundColor: initialColor, stockSeq: 10, },
  { backgroundColor: initialColor, stockSeq: 11, },
  { backgroundColor: initialColor, stockSeq: 12, },
  { backgroundColor: initialColor, stockSeq: 13, },
  { backgroundColor: initialColor, stockSeq: 14, },
]

export const getInitialLayouts = () => {
  return [
    {
      categoryName: 'Plat 1',
      id: uuidv4(),
      categorySeq: 0,
      stockList: [...initialGrid]
    },
    {
      categoryName: 'Plat 2',
      id: uuidv4(),
      categorySeq: 1,
      stockList: [...initialGrid]
    },
    {
      categoryName: 'Plat 3',
      id: uuidv4(),
      categorySeq: 2,
      stockList: [...initialGrid]
    },
    {
      categoryName: 'Plat 4',
      id: uuidv4(),
      categorySeq: 3,
      stockList: [...initialGrid]
    },
    {
      categoryName: 'Plat 5',
      id: uuidv4(),
      categorySeq: 4,
      stockList: [...initialGrid]
    },
    {
      categoryName: 'Plat 6',
      id: uuidv4(),
      categorySeq: 5,
      stockList: [...initialGrid]
    },
  ]
}
