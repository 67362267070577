import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { salesRequest } from '../apis'

const initialState = []

export const fetchSalePayments = createAsyncThunk('salePayments/fetchSalePayments', async (params, thunkAPI) => {
  const { data } = await salesRequest.get('/payment/summary', {
    headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
    params: params,
  })

  return data.list
})

const salePayments = createSlice({
  name: 'salePayments',
  initialState,
  reducers: {
    replaceSalePayments: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    [fetchSalePayments.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchSalePayments.rejected]: (state, action) => {
      return []
    },
  },
})

export const { replaceSalePayments } = salePayments.actions

export default salePayments.reducer
