import React, { Suspense, lazy } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { SWRConfig } from 'swr'
import { fetcher } from '../helpers/fetcher'
import withTheme from './withTheme'
import Header from './Header'
// import { connect } from 'react-redux'
// import { updateCompanyInfo } from '../slices/companyInfoSlice'

const PosPlatform = lazy(() => import('./PosPlatform/PosPlatform'))
const PosOrderList = lazy(() => import('./PosOrderList/PosOrderList'))
const Signin = lazy(() => import('./Auth/Signin'))
const CommManage = lazy(() => import('./CommManage/CommManage'))
const CompanyConfig = lazy(() => import('./CompanyConfig/CompanyConfig'))
const TouchPlatformBackstage = lazy(() => import('./LayoutManage/LayoutManage'))


class App extends React.Component {
  // componentDidMount() {
  //   if (window.localData.companyInfo) {
  //     this.props.updateCompanyInfo(window.localData.companyInfo)
  //   }
  // }

  render() {
    return (
      <SnackbarProvider
        maxSnack={4}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SWRConfig
          value={{
            fetcher,
          }}
        >
          <Router>
            <Header />
            <Suspense fallback={null}>
              <Switch>
                <Route exact path="/" component={PosPlatform} />
                <Route path="/pos-order-list" component={PosOrderList} />
                <Route path="/signin" component={Signin} />
                <Route path="/backstage/layout" component={TouchPlatformBackstage} />
                <Route path="/company-config" component={CompanyConfig} />
                <Route path="/backstage/comm" component={CommManage} />
              </Switch>
            </Suspense>
          </Router>
        </SWRConfig>

      </SnackbarProvider>
    )
  }
}

// export default connect(null, { updateCompanyInfo })(App)
export default withTheme(App)