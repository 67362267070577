import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  1: '#22B8BB',
  2: '#DCDE3F',
  3: '#FFFFFF',
  4: '#FFAA67',
  5: '#FFDA64',
  6: '#FFE5E5',
}

export const paletteSlice = createSlice({
  name: 'palette',
  initialState,
  reducers: {
    editPreviousPalette: (state, action) => {
      return { 1: action.payload, 2: state[1], 3: state[2], 4: state[3], 5: state[4], 6: state[5] }
    },
  },
  extraReducers: {
    'signout': (state, action) => {
      return initialState
    },
    'changeSalePoint': (state, action) => {
      return initialState
    },
  },
})

export const { editPreviousPalette } = paletteSlice.actions

export const selectPalette = state => state.palette

export default paletteSlice.reducer