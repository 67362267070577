import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { commRequest } from '../apis'

const initialState = []

const commsFetcher = async (params, token) => {
  const response = await commRequest.get('', {
    headers: { 'Authorization': 'Bearer ' + token },
    params: {
      sort: 'sale_start_date desc',
      ...params
    },
  })

  return response.data.list
}

// const commByIdFetcher = async (id, token) => {
//   try {
//     const response = await commRequest.get(`/${id}`, {
//       headers: { 'Authorization': 'Bearer ' + token },
//     })
//     return response.data
//   } catch (error) {
//     if (error.response.status === 404) {
//       return null
//     }
//   }
// }



export const fetchComms = createAsyncThunk('comms/fetchComms', async (params, thunkAPI) => {
  const result = await commsFetcher(params, thunkAPI.getState().auth.authenticated)
  return result
})

export const fetchCommsDiverse = createAsyncThunk('comms/fetchCommsDiverse', async (text, thunkAPI) => {
  const token = thunkAPI.getState().auth.authenticated
  // const idResult = text.trim() ? await commByIdFetcher(text, token) : null
  const idResult = await commsFetcher({ stockIds: text }, token)
  const nameResult = await commsFetcher({ name: text }, token)
  const barcodeResult = await commsFetcher({ internationalBarcode: text }, token)

  const redundantFlag = idResult[0] && barcodeResult[0] && (idResult[0].stockId === barcodeResult[0].stockId)

  return [
    ...(idResult ? idResult : []),
    ...(redundantFlag ? [] : barcodeResult),
    ...nameResult
  ]
})


export const commsSlice = createSlice({
  name: 'comms',
  initialState,
  reducers: {
    replaceComms: (state, action) => {
      return action.payload
    },
    createComm: (state, action) => {
      return [...state, action.payload]
    },
    editComm: (state, action) => {
      return state.map(el => el.stockId === action.payload.stockId ? action.payload : el)
    },
    deleteComm: (state, action) => {
      return state.filter(comm => comm.stockId !== action.payload)
    },
  },
  extraReducers: {
    [fetchComms.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchComms.rejected]: (state, action) => {
      return []
    },
    [fetchCommsDiverse.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchCommsDiverse.rejected]: (state, action) => {
      return []
    },
    'signout': (state, action) => {
      return []
    },
    'changeSalePoint': (state, action) => {
      return []
    },
  },
})

export const { replaceComms, createComm, editComm, deleteComm } = commsSlice.actions

export const selectComms = state => state.comms

export default commsSlice.reducer

