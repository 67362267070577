import axios from 'axios'
const baseURL = 'https://api.service.drvet.info/api'

export const fetcher = async (...args) => {
  const [url, token, params] = args
  const response = await axios.get(baseURL + url, {
    headers: {
      'Authorization': token && `Bearer ${token}`,
    },
    params: {
      ...params
    },
  })

  // 注意: 原本 response.statusText 是判斷 'OK' 現在暫時改成 '' 代表成功
  if (response.statusText !== '') {
    const error = new Error('An error occurred while fetching the data.')

    error.info = response.statusText
    error.status = response.status
    throw error
  }

  return response.data
}

// const dummyRes = {
//   "data": {
//     "currentPage": 1,
//     "list": [],
//     "totalCount": 2350,
//     "totalPage": 157
//   },
//   "status": 200,
//   "statusText": "OK",
//   "headers": {
//     "content-type": "application/json; charset=utf-8"
//   },
//   "config": {
//     "transitional": {
//       "silentJSONParsing": true,
//       "forcedJSONParsing": true,
//       "clarifyTimeoutError": false
//     },
//     "transformRequest": [
//       null
//     ],
//     "transformResponse": [
//       null
//     ],
//     "timeout": 0,
//     "xsrfCookieName": "XSRF-TOKEN",
//     "xsrfHeaderName": "X-XSRF-TOKEN",
//     "maxContentLength": -1,
//     "maxBodyLength": -1,
//     "headers": {
//       "Accept": "application/json, text/plain, */*",
//       "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0Tm8iOiJkZW1vIiwiZXhwIjoxNjUyMzQwODYzLCJ0YXJnZXQiOiJkZW1vX2RydmV0X3NlcnZlciIsInVzZXJJZCI6InRlc3QxMjMifQ.rR90Plt7NCJJjtzbHMRNShKYPpQnajC4BFELUAJgr4A"
//     },
//     "baseURL": "https://apidrvet.drvet.info/api/stock-master",
//     "params": {
//       "sort": "sale_start_date desc",
//       "limit": 15
//     },
//     "method": "get",
//     "url": ""
//   },
//   "request": {}
// }


