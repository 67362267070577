import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { salesRequest } from '../apis'
import { fireDialogToast } from '../helpers/sweetAlert'
import { v4 as uuidv4 } from 'uuid'

const initialState = {}

export const fetchSales = createAsyncThunk('sales/fetchSales', async (params, thunkAPI) => {
  const response = await salesRequest.get('', {
    headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
    params: {
      sort: 'date desc',
      ...params
    },
  })
  if (response.status === 403) {
    fireDialogToast('警告: 權限不足', 'warning')
    return {}
  }
  return response.data
})

export const transferSaleArrear = createAsyncThunk('sales/transferSaleArrear', async (params, thunkAPI) => {
  try {
    const { saleId, arrearId, paymentId, value } = params
    const paymentsMapping = thunkAPI.getState().paymentMethods.content

    const data = {
      originSalesPaymentsId: arrearId,
      repaySalePointPaymentId: paymentId,
      value,
    }
    await salesRequest.post(`/repay/${saleId}`, data, {
      headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
    })

    return { ...params, paymentsMapping }
  } catch (error) {
    fireDialogToast('錯誤: 還款失敗', 'error')
  }
})

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    replaceSales: (state, action) => {
      return action.payload
    },
    deleteSale: (state, action) => {
      state.list = state.list.filter(el => el.id !== action.payload)
    },
  },
  extraReducers: {
    [fetchSales.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchSales.rejected]: (state, action) => {
      return {}
    },
    [transferSaleArrear.fulfilled]: (state, action) => {
      const { saleId, arrearId, paymentId, value, paymentsMapping } = action.payload
      const sale = state.list.find(el => el.id === saleId)
      const originPayments = sale.salesPayments

      const arrear = originPayments.find(el => el.id === arrearId)
      if (arrear.value <= 0) return state
      arrear.value -= value

      const payment = {
        id: uuidv4(),
        SalePointPayment: paymentsMapping[paymentId],
        value,
      }
      const newPayments = [...originPayments, payment]
      sale.salesPayments = newPayments
    },
    'signout': (state, action) => {
      return {}
    },
    'changeSalePoint': (state, action) => {
      return {}
    },
  }
})

export const { replaceSales, deleteSale } = salesSlice.actions

export default salesSlice.reducer
