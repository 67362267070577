import { createSlice } from '@reduxjs/toolkit'
import { initialColor, initialGrid } from '../constants'

const initialState = []

export const posLayoutDraftsSlice = createSlice({
  name: 'posLayoutDrafts',
  initialState,
  reducers: {
    editPosLayoutDrafts: (state, action) => {
      return action.payload
    },
    addPosLayoutDraft: (state, action) => {
      return [...state, { ...action.payload, stockList: initialGrid }]
    },
    editPosLayoutDraft: (state, action) => {
      return state.map(el => el.id === action.payload.id ? action.payload.layout : el)
    },
    deletePosLayoutDraft: (state, action) => {
      return state.filter(el => {
        return el.id !== action.payload
      })
    },
    editPosLayoutDraftItem: (state, action) => {
      return state.map(el => {
        if (el.id === action.payload.id) {
          const newContent = [...el.stockList]
          newContent[action.payload.index] = action.payload.item;
          return { ...el, stockList: newContent }
        } else {
          return el
        }
      })
    },
    clearPosLayoutDraftItem: (state, action) => {
      return state.map(el => {
        if (el.id === action.payload.id) {
          const newContent = [...el.stockList]
          newContent[action.payload.index] = {
            stockSeq: action.payload.index,
            backgroundColor: initialColor,
          }
          return { ...el, stockList: newContent }
        } else {
          return el
        }
      })
    },
  },
  extraReducers: {
    'signout': (state, action) => {
      return []
    },
    'changeSalePoint': (state, action) => {
      return []
    },
  },
})

export const { editPosLayoutDrafts, addPosLayoutDraft, editPosLayoutDraft, deletePosLayoutDraft, editPosLayoutDraftItem, clearPosLayoutDraftItem } = posLayoutDraftsSlice.actions

export const selectPosLayoutDrafts = state => state.posLayoutDrafts

export default posLayoutDraftsSlice.reducer