import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const creditCardResponseSlice = createSlice({
  name: 'creditCardResponse',
  initialState,
  reducers: {
    replaceCreditCardResponse: (state, action) => {
      return action.payload
    },
  }
})

export const { replaceCreditCardResponse } = creditCardResponseSlice.actions
export const selectCreditCardResponse = state => state.creditCardResponse

export default creditCardResponseSlice.reducer