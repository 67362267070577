import { createSlice } from '@reduxjs/toolkit'

const initialState = ''

const orderModeSlice = createSlice({
  name: 'orderMode',
  initialState,
  reducers: {
    updateOrderMode: (state, action) => {
      return action.payload
    }
  }
});

export const { updateOrderMode } = orderModeSlice.actions

export const selectOrderMode = state => state.orderMode

export default orderModeSlice.reducer