import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { layoutRequest } from '../apis'
import { getInitialLayouts } from '../constants'

const initialState = {
  content: getInitialLayouts(),
  loading: 'initial',
}

const mapIndexToCategorySeq = (list) => {
  return list.map((item, index) => ({ ...item, categorySeq: index }))
}

// Thunk Action Part
export const fetchPosLayouts = createAsyncThunk('posLayouts/fetchPosLayouts', async (id, thunkAPI) => {
  const salePointId = id || thunkAPI.getState().user.salePointId
  const { data } = await layoutRequest.get(salePointId, {
    headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated },
  })
  return data
})

export const postPosLayouts = (posLayoutDrafts, callback, errorCallback) => async (dispatch, getState) => {
  try {
    const mappedSeqDraft = mapIndexToCategorySeq(posLayoutDrafts)
    const data = { list: mappedSeqDraft }
    const { auth: { authenticated }, user: { salePointId } } = getState()
    dispatch({ type: 'posLayouts/postPosLayouts/pending' })
    await layoutRequest.post(salePointId, data, {
      headers: { 'Authorization': 'Bearer ' + authenticated },
    })
    dispatch({
      type: 'posLayouts/postPosLayouts/fulfilled',
      payload: mappedSeqDraft,
    })

    callback()

  } catch (error) {
    dispatch({ type: 'posLayouts/postPosLayouts/rejected' })
    errorCallback()
  }
}

// Slice Part
export const posLayoutsSlice = createSlice({
  name: 'posLayouts',
  initialState,
  reducers: {
    replacePosLayouts: (state, action) => {
      state.content = action.payload
    },
  },
  extraReducers: {
    [fetchPosLayouts.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [fetchPosLayouts.fulfilled]: (state, action) => {
      state.content = (action.payload.length > 0)
        ? action.payload
        : getInitialLayouts()
      state.loading = 'idle'
    },
    [fetchPosLayouts.rejected]: (state, action) => {
      state.loading = 'idle'
    },
    'posLayouts/postPosLayouts/pending': (state, action) => {
      state.loading = 'pending'
    },
    'posLayouts/postPosLayouts/fulfilled': (state, action) => {
      state.content = action.payload
      state.loading = 'idle'
    },
    'posLayouts/postPosLayouts/rejected': (state, action) => {
      state.loading = 'idle'
    },
    'signout': (state, action) => {
      return initialState
    },
  },
})

export const { replacePosLayouts } = posLayoutsSlice.actions

export const selectPosLayouts = state => state.posLayouts.content

export default posLayoutsSlice.reducer