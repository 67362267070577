import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { externalOrderRequest } from '../apis'
import { fireDialogToast } from '../helpers/sweetAlert'

export const fetchActiveExternalOrder = createAsyncThunk('activeExternalOrder/fetchActiveExternalOrder', async (params, thunkAPI) => {
  const { data } = await externalOrderRequest.get(`/${params.id}`, {
    headers: { 'Authorization': 'Bearer ' + thunkAPI.getState().auth.authenticated }
  })
  return data
})


// Slice part
export const externalOrdersSlice = createSlice({
  name: 'externalOrders',
  initialState: [],
  reducers: {
    replaceExternalOrders: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    'signout': (state, action) => {
      return []
    },
    'changeSalePoint': (state, action) => {
      return []
    },
  },
})

export const activeExternalOrderSlice = createSlice({
  name: 'activeExternalOrder',
  initialState: {},
  reducers: {
    replaceActiveExternalOrder: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    [fetchActiveExternalOrder.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchActiveExternalOrder.rejected]: (state, action) => {
      return {}
    },
    'addSelectedExternalOrder': (state, action) => {
      return action.payload
    },
    'signout': (state, action) => {
      return {}
    },
    'changeSalePoint': (state, action) => {
      return {}
    },
  },
})

export const selectedExternalOrdersSlice = createSlice({
  name: 'selectedExternalOrders',
  initialState: [],
  reducers: {
    removeSelectedExternalOrder: (state, action) => {
      return state.filter(item => item.id !== action.payload)
    },
    replaceSelectedExternalOrders: (state, action) => {
      return action.payload
    },
    clearSelectedExternalOrders: (state, action) => {
      return []
    },
  },
  extraReducers: {
    'addSelectedExternalOrder': (state, action) => {
      return [action.payload, ...state]
    },
    'signout': (state, action) => {
      return []
    },
    'changeSalePoint': (state, action) => {
      return []
    },
  },
})

export const addSelectedExternalOrder = order => ({
  type: 'addSelectedExternalOrder',
  payload: order
})

// redux thunk
export const fetchAddSelectedExternalOrder = params => async (dispatch, getState) => {
  try {
    const { data } = await externalOrderRequest.get(`/${params.id}`, {
      headers: { 'Authorization': 'Bearer ' + getState().auth.authenticated }
    })

    dispatch({ type: 'addSelectedExternalOrder', payload: data })

  } catch (error) {
    fireDialogToast(
      error.response?.data?.error
        ? `Status Code ${error.response.status}: ${error.response.data.error}`
        : error,
      'error'
    )
  }
}

export const filterSelectedExternalOrders = (orders, oldSelectedOrders) => async (dispatch, getState) => {
  const ordersMapping = {}
  orders.forEach(order => {
    ordersMapping[order.id] = order
  })

  const newSelectedOrders = []
  oldSelectedOrders.forEach(order => {
    if (!ordersMapping[order.id]) {
      newSelectedOrders.push(order)
    }
  })

  dispatch(replaceSelectedExternalOrders(newSelectedOrders))
}

export const selectAllExternalOrders = (orders, oldSelectedOrders) => async (dispatch, getState) => {
  try {
    // 串接獲得各訂單的細項 tempSalesDetails
    const fetchedOrders = await Promise.all(orders.map(async (order) => {
      const { data } = await externalOrderRequest.get(`/${order.id}`, {
        headers: { 'Authorization': 'Bearer ' + getState().auth.authenticated }
      })
      return data
    }))

    const oldSelectedOrdersMapping = {}
    oldSelectedOrders.forEach(order => {
      oldSelectedOrdersMapping[order.id] = order
    })

    const newSelectedOrders = []
    fetchedOrders.forEach(order => {
      if (!oldSelectedOrdersMapping[order.id]) {
        newSelectedOrders.push(order)
      }
    })

    dispatch(replaceSelectedExternalOrders([
      ...newSelectedOrders,
      ...oldSelectedOrders
    ]))
  } catch (error) {
    fireDialogToast(
      error.response?.data?.error
        ? `Status Code ${error.response.status}: ${error.response.data.error}`
        : error,
      'error'
    )
  }
}

// action export
export const { replaceExternalOrders } = externalOrdersSlice.actions
export const { replaceActiveExternalOrder } = activeExternalOrderSlice.actions
export const { removeSelectedExternalOrder, replaceSelectedExternalOrders, clearSelectedExternalOrders } = selectedExternalOrdersSlice.actions

// selector export
export const selectExternalOrders = state => state.externalOrders
export const selectActiveExternalOrder = state => state.activeExternalOrder
export const selectSelectedExternalOrders = state => state.selectedExternalOrders

// reducer export
export const externalOrdersReducer = externalOrdersSlice.reducer
export const activeExternalOrderReducer = activeExternalOrderSlice.reducer
export const selectedExternalOrdersReducer = selectedExternalOrdersSlice.reducer