import axios from 'axios'
import { store } from '../store'
import { signout } from '../slices/authSlice'

let isSignout = false
const withInterceptor = (instance) => {
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401 && !isSignout) {
        store.dispatch(signout())
        isSignout = true
        setTimeout(() => {
          isSignout = false
        }, 100)
      }
      return Promise.reject(error)
    }
  )
  return instance
}

export const basicRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api' })
)

export const userRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/user' })
)

export const companyRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/company' })
)


export const memberRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/member' })
)

export const commRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/stock-master' })
)

export const commClassRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/stock-category' })
)

export const externalOrderRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/temp-sales', })
)

export const salePointRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/pos', })
)

export const layoutRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/pos/stock/grid' })
)

export const salesRequest = withInterceptor(
  axios.create({ baseURL: 'https://api.service.drvet.info/api/sales', })
)




// https://api.service.drvet.info
// http://35.234.32.121:8088