import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTheme, alpha, Box, Typography, IconButton } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Switch from './ui/Switch'
// import { useSelector } from 'react-redux'
// import { io } from 'socket.io-client'
// import { useSnackbar } from 'notistack'
// import { selectAuthUser } from '../slices/authSlice'
// import CustomSnackbar from '../components/Element/CustomSnackbar'
// import { useHistory } from 'react-router-dom'
// import { changeSalePoint } from '../slices/userSlice'
import { initialVisits } from '../slices/visitsSlice'
import { signout } from '../slices/authSlice'
import { updateOrderMode } from '../slices/orderModeSlice'
import headerIcon from '../images/headerIcon.png'
import { isElectron } from '../constants'


const Header = ({
  auth,
  user,
  orderMode,
  signout,
  updateOrderMode,
}) => {
  const theme = useTheme()
  // const [socket, setSocket] = useState(null)
  // const { enqueueSnackbar } = useSnackbar()
  // const auth = useSelector(selectAuthUser)
  // const history = useHistory()

  const onSignoutClick = () => {
    signout()
  }

  const handleOrderModeToggle = e => {
    updateOrderMode(e.target.checked ? '' : 'normal')
    localStorage.setItem(`orderMode/${user.userId}/${user.salePointId}`, e.target.checked ? '' : 'normal')
  }

  // const connectSocketIO = () => {
  //   // Namespace 之後改成與門市相關，例如門市ID
  //   const newSocket = io('http://localhost:9000/')
  //   newSocket.on('connect', () => { console.log('connect') })
  //   newSocket.on('disconnect', () => { console.log('disconnect disconnect disconnect') })
  //   setSocket(newSocket)
  // }
  // useEffect(() => {
  //   // 之後改成檢查是否有門市相關，例如門市ID
  //   if (socket && !auth) {
  //     socket.disconnect()
  //     setSocket(null)
  //   }
  //   if (!socket && auth) connectSocketIO()
  // }, [auth, socket])

  // useEffect(() => {
  //   if (socket) {
  //     socket.on('getNotification', message => {
  //       enqueueSnackbar('new message', {
  //         variant: 'info',
  //         content: (key, message) => (
  //           <CustomSnackbar message={message} />
  //         )
  //       })
  //     })
  //     socket.emit('startNotify', '請開始發送通知')
  //   }
  // }, [socket, enqueueSnackbar])

  // const onSalePointChange = e => {
  //   changeSalePoint(e.target.value)
  //   initialVisits(e.target.value)
  //   history.push('/')
  // }

  // const renderOptions = salePoints => {
  //   return salePoints && salePoints.map(point => (
  //     <option key={point.id} value={point.id}>{point.salePointName}</option>
  //   ))
  // }

  return (
    <Box component="header"
      sx={{
        zIndex: 10,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'background.default',
        height: '2.5rem',
        boxShadow: 'inset 0px 0px 12px -5px #dddddd',
        '& > div:first-of-type': {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          'a': {
            height: '100%',
          },
        },
        '& > div:last-of-type': {
          paddingRight: '1rem',
        },
      }}
    >
      <div>
        <NavLink to="/">
          <Box component="img" src={user.logo || headerIcon} alt="header-icon" sx={{
            padding: '0 0.75em',
            height: '100%',
            // width: '6rem', 
            // height: '1.6rem'
          }} />
        </NavLink>
        {auth
          ? (
            <Box component="div"
              sx={{
                flex: '1',
                display: 'inline-flex',
                alignSelf: 'stretch',
                '& a': {
                  textDecoration: 'none',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: '#333333',
                  margin: '0 0.25em',
                  padding: '1em 0.5em 0',
                  userSelect: 'none',
                  transition: 'all 0.2s linear',
                },
                '& a:hover': {
                  color: theme.palette.primary.main,
                },

                '& a.active': {
                  color: theme.palette.primary.main,
                  borderBottom: `0.35em solid ${theme.palette.primary.main}`,
                },
              }}
            >
              <NavLink to="/" activeClassName="active" exact>
                POS首頁
              </NavLink>
              <NavLink to="/pos-order-list" activeClassName="active">
                銷售明細
              </NavLink>
              <NavLink to="/backstage/comm" activeClassName="active">
                商品目錄
              </NavLink>
              <NavLink to="/backstage/layout" activeClassName="active">
                網格管理
              </NavLink>
            </Box>
          )
          : null
        }
      </div>

      {auth
        ? (
          <Box component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& select': {
                color: '#666666',
                border: '1px solid #dddddd',
                borderRadius: '0.25em',
                mr: '0.5em',
              },
              '& span': {
                color: 'secondary.main',
              },
              '& .MuiSvgIcon-root': {
                color: 'secondary.main',
              },
            }}
          >
            {/* <select
              value={user.salePointId}
              onChange={onSalePointChange}
            >
              {renderOptions(user.salePoints)}
            </select> */}

            {
              isElectron
                ? (
                  <NavLink to="/company-config" activeClassName="active">
                    <IconButton size="small">
                      <SettingsIcon fontSize="small" />
                    </IconButton>
                  </NavLink>
                )
                : (
                  <Box
                    sx={{
                      px: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'background.darkPaper',
                      borderRadius: 2,
                      '.MuiSwitch-root': {
                        mx: 1,
                      },
                      '.MuiSwitch-thumb': {
                        bgcolor: 'background.light',
                      },
                      '.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.5)
                      }
                    }}
                  >
                    <Typography>訂單</Typography>
                    <Switch
                      aria-labelledby="order-mode"
                      checked={orderMode === ''}
                      onChange={handleOrderModeToggle}
                    />
                    <Typography>批價單</Typography>
                  </Box>
                )
            }
            <IconButton size="small" onClick={onSignoutClick}>
              <ExitToAppIcon fontSize="small"
                sx={{
                  transition: 'all 0.2s ease-out',
                  '&:hover': {
                    color: 'error.light',
                  },
                }}
              />
            </IconButton>
          </Box>
        )
        : null
      }
    </Box>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
    auth: state.auth.authenticated,
    orderMode: state.orderMode,
  }
}

export default connect(mapStateToProps, {
  signout,
  initialVisits,
  updateOrderMode,
  // changeSalePoint,
})(Header)


