import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // 1: 1000,
  // 2: 2000,
  // '04fddc85-da6a-11e7-aba2-42010af00002': 1200,
}

export const receivedAmountsSlice = createSlice({
  name: 'receivedAmounts',
  initialState,
  reducers: {
    updateReceivedAmounts: (state, action) => {
      return { ...state, ...action.payload }
    },
    replaceReceivedAmounts: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    'checkout': (state, action) => {
      return {}
    },
    'signout': (state, action) => {
      return {}
    },
    'changeSalePoint': (state, action) => {
      return {}
    },
  },
})

export const { updateReceivedAmounts, replaceReceivedAmounts } = receivedAmountsSlice.actions

export const selectReceivedAmounts = state => state.receivedAmounts

export default receivedAmountsSlice.reducer