import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const MySwal = withReactContent(Swal)

export const Toast = MySwal.mixin({
  icon: 'warning',
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  willOpen: () => {
    MySwal.getContainer().style.position = 'absolute';
  },
  didOpen: toast => {
    toast.addEventListener('mouseenter', MySwal.stopTimer)
    toast.addEventListener('mouseleave', MySwal.resumeTimer)
  },
})

export const fireDialogToast = (title, icon) => {
  Toast.fire({
    ...(icon && { icon }),
    title,
    target: document.querySelector('.MuiDialog-root')
  })
}

export const fireErrorToast = (error, targetDomName) => {
  Toast.fire({
    icon: 'error',
    title: `錯誤: ${error.info || error?.response?.data?.error || '獲取資料失敗'}`,
    target: document.querySelector(targetDomName)
  })
}

