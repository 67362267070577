import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const currentMemberSlice = createSlice({
  name: 'currentMember',
  initialState,
  reducers: {
    replaceCurrentMember: (state, action) => {
      return action.payload
    },
  },
  extraReducers: {
    'checkout': (state, action) => {
      return {}
    },
    'signout': (state, action) => {
      return {}
    },
    'changeSalePoint': (state, action) => {
      return {}
    },
  },
})

export const { replaceCurrentMember } = currentMemberSlice.actions

export const selectCurrentMember = state => state.currentMember

export default currentMemberSlice.reducer