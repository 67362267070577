import { createSlice } from '@reduxjs/toolkit'

// const timestamp = Date.now()
const demoTempOrder = [
  {
    id: '91b3c514-daf6-4b33-a71d-ff5fe701b529',
    timestamp: 1642059528103,
    totalQuantity: 4,
    totalAmount: 4450,
    member: {},
    content: [
      {
        id: 'a3d603ea-ac89-4510-8e98-07da4e2e40b1',
        stockId: '5027314501233',
        stockName: 'Fibreplex 鮮兔纖 15ml (條)',
        // commCategoryName: '[------ 銷售類商品 ------]',
        quantity: 1,
        discount: '50',
        price: 450,
        salePrice: 400,
        originalTotal: 450,
        total: 400
      },
      {
        id: 'ce7181bf-2477-467e-9124-03c6a56dcf8c',
        stockId: '304346',
        stockName: 'Virbac維克 Nutri-plus gel 克補軟膏 (條)',
        // commCategoryName: '[------ 銷售類商品 ------]',
        quantity: 2,
        discount: '%5',
        price: 450,
        salePrice: 225,
        originalTotal: 900,
        total: 450
      },
      {
        id: '95c1170f-af6c-410f-bcba-652ed536819e',
        stockId: '810020601',
        stockName: 'SIMGA T型連接管 100條 (盒)',
        // commCategoryName: '輸液套、輸液管',
        quantity: 1,
        discount: '',
        price: 3600,
        salePrice: 3600,
        originalTotal: 3600,
        total: 3600
      }
    ]
  },
  {
    id: 'f2907995-8aec-4f1a-8af4-e35e31c1a8a1',
    timestamp: 1642055846878,
    totalQuantity: 8,
    totalAmount: 1050,
    member: {
      id: '0164AC40-665F-05A9-83A1-608429428429',
      memberCode: 'CU002499',
      memberName: '陳Ｏ妤',
      email: '',
      birthdate: null,
      phone: '0966oooooo',
      companyPhone: '',
      homePhone: '',
      totalConsumption: 50,
      accumulatedBonus: 0,
      salePointId: '',
      transactionSalePointId: '3ab0ef5c-4e3e-11e6-a94b-42010af00003',
      pets: [
        {
          id: '82EE0E42-BA04-F242-6811-4E20982E20FA',
          memberId: '0164AC40-665F-05A9-83A1-608429428429',
          petCode: '',
          petName: '腸腸',
          genderId: '316A7127-127A-4EC3-A57B-118F28F0EB7B',
          gender: {
            id: ''
          },
          birthday: '2017-07-04',
          petTypeId: '64DC5C6B-AD0A-FCC1-E467-A5C6B5C9C0FC',
          petVarietyId: 'BDDBD805-EDA9-039A-8CEF-81BAC32B3E55',
          icCard: '0'
        }
      ]
    },
    content: [
      {
        id: 'c299b56d-e398-476a-8410-71dd4f58f0bc',
        stockId: '5027314501240',
        stockName: 'Bio-Lapis 寶礦兔 2g/6包 (盒)',
        // commCategoryName: '[------ 銷售類商品 ------]',
        quantity: 1,
        discount: '',
        price: 400,
        salePrice: 400,
        originalTotal: 400,
        total: 400
      },
      {
        id: '05cc27c2-c14c-48e7-bd9c-71a4df863ca8',
        stockId: '5031812507000-1',
        stockName: 'VetPlus 適肝能 SAMYLIN 小型犬貓粉劑 1 (包)',
        // commCategoryName: '[------ 銷售類商品 ------]',
        quantity: 5,
        discount: '',
        price: 50,
        salePrice: 50,
        originalTotal: 250,
        total: 250
      },
      {
        id: '2e14e56b-1605-47ca-aaec-8542610d9afd',
        stockId: '806010003',
        stockName: 'E collar (包邊)頭套 (5)號',
        // commCategoryName: '傷口照護',
        quantity: 2,
        discount: '100',
        price: 250,
        salePrice: 200,
        originalTotal: 500,
        total: 400
      }
    ]
  },
  // {
  //   ID: 'temp23uf',
  //   name: 'Temp 1',
  //   timestamp: timestamp,
  //   totalQuantity: 4,
  //   totalAmount: 1680,
  //   member: {
  //     ID: 'CU005891',
  //     memberName: '王亦明',
  //     mobile: '0912345611',
  //     tel: '02-25123456'
  //   },
  //   content: [
  //     {
  //       id: '41644b26-5e83-4983-93c4-f6fe0af7a7e2',
  //       stockName: 'Card 8',
  //       price: 550,
  //       backgroundColor: '#fcfcfc',
  //       stockId: '4719850620042',
  //       quantity: 2,
  //       discount: '100',
  //       total: 1000
  //     },
  //     {
  //       id: '9eaa7514-935c-45df-bc64-93fbe7c95037',
  //       stockName: 'Card 7',
  //       price: 1200,
  //       backgroundColor: '#fcfcfc',
  //       stockId: '471412747203401',
  //       quantity: 2,
  //       discount: '100',
  //       total: 2300
  //     },
  //     {
  //       id: 'c1999857-67db-435a-99f2-0095ea39e01b',
  //       stockName: 'Card 6',
  //       price: 300,
  //       backgroundColor: '#fcfcfc',
  //       stockId: '4714127472034',
  //       quantity: 1,
  //       discount: '10%',
  //       total: 270
  //     },
  //     {
  //       id: 'e5841995-e10c-4e68-806a-111f6c90ea63',
  //       stockName: 'Card 5',
  //       price: 2000,
  //       backgroundColor: '#fcfcfc',
  //       stockId: '3182550815505',
  //       quantity: 1,
  //       discount: '',
  //       total: 2000
  //     },
  //     {
  //       id: 'eb86f2c0-6616-4c39-922f-c19f6bcf2c87',
  //       stockName: 'Card 4',
  //       price: 1250,
  //       backgroundColor: '#fcfcfc',
  //       stockId: '3182550711425',
  //       quantity: 3,
  //       discount: '',
  //       total: 3750
  //     }
  //   ],
  // },
]

export const tempOrdersSlice = createSlice({
  name: 'tempOrders',
  initialState: [],
  reducers: {
    replaceTempOrders: (state, action) => {
      return action.payload
    },
    addTempOrder: (state, action) => {
      return [action.payload, ...state]
    },
    editTempOrder: (state, action) => {
      return state.map(el => el.id === action.payload.id ? { ...el, content: action.payload.order } : el)
    },
    deleteTempOrder: (state, action) => {
      return state.filter(el => el.id !== action.payload)
    },
  },
  extraReducers: {
    'signout': (state, action) => {
      return []
    },
    'changeSalePoint': (state, action) => {
      return []
    },
  },
})

export const { replaceTempOrders, addTempOrder, editTempOrder, deleteTempOrder } = tempOrdersSlice.actions

export const getTempOrderWithLocal = () => (dispatch, getState) => {
  const { userId, salePointId } = getState().user
  const newTempOrders = JSON.parse(localStorage.getItem(`tempOrders/${userId}/${salePointId}`)) || demoTempOrder
  localStorage.setItem(`tempOrders/${userId}/${salePointId}`, JSON.stringify(newTempOrders))

  dispatch(replaceTempOrders(newTempOrders))
}

export const addTempOrderWithLocal = order => (dispatch, getState) => {
  const { userId, salePointId } = getState().user
  const tempOrders = JSON.parse(
    localStorage.getItem(`tempOrders/${userId}/${salePointId}`)
  ) || []
  const newTempOrders = tempOrders ? [order, ...tempOrders] : [order]
  localStorage.setItem(`tempOrders/${userId}/${salePointId}`, JSON.stringify(newTempOrders))

  dispatch(addTempOrder(order))
}

// export const editTempOrderWithLocal = (id, order) => dispatch => {
//   const tempOrders = JSON.parse(localStorage.getItem('tempOrders'))
//   if (tempOrders) {
//     const newTempOrders = tempOrders.map(el => el.id === id ? { ...el, content: order } : el)
//     localStorage.setItem('tempOrders', JSON.stringify(newTempOrders))
//   }

//   dispatch(editTempOrder({ id, order }))
// }

export const deleteTempOrderWithLocal = id => (dispatch, getState) => {
  const { userId, salePointId } = getState().user
  const tempOrders = JSON.parse(
    localStorage.getItem(`tempOrders/${userId}/${salePointId}`)
  )

  if (tempOrders) {
    const newTempOrders = tempOrders.filter(el => el.id !== id)
    localStorage.setItem(`tempOrders/${userId}/${salePointId}`, JSON.stringify(newTempOrders))
  }

  dispatch(deleteTempOrder(id))
}



export const selectTempOrders = state => state.tempOrders

export default tempOrdersSlice.reducer